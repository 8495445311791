/* eslint-disable no-underscore-dangle */
/* eslint-disable @next/next/no-img-element */
import client from '../apollo-client';
import ComponentParser from '../cms/ComponentParser';
import { GET_HOMEPAGE } from '../graphql/home-page';
import { GET_ALL_TOPICS } from '../graphql/topics';
import { useMemo } from "react";
import { shuffleArray } from "../utils";

export default function Home({ content, popularTopics, featuredTrips }) {
  const randomizedFeaturedTrips = useMemo(() => shuffleArray(featuredTrips), featuredTrips);
  return (
    <div className="content-wrapper">
      {content?.components?.map((component) => (
        <ComponentParser
          popularTopics={popularTopics}
          featuredTrips={randomizedFeaturedTrips}
          key={component.id + component.__typename}
          data={component}
        />
      ))}
    </div>
  );
}

export async function getStaticProps() {
  const { data } = await client.query({
    query: GET_HOMEPAGE,
  });
  const { data: topics } = await client.query({
    query: GET_ALL_TOPICS,
    variables: {
      limit: 100,
    }
  });
  return {
    props: {
      content: data?.homePage?.data?.attributes || {},
      popularTopics: topics?.topics?.data,
      featuredTrips: data?.trips?.data,
      theme: data?.homePage?.data?.attributes?.theme || 'light',
    }, // will be passed to the page component as props
    revalidate: Number(process.env.REVALIDATE_TIME_SECONDS),
  };
}
